import React from "react"

import Layout from "../layout"
import SEO from "../components/seo/seo"
import Projects from "../components/projects/projects";

import "./index.scss"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={["Portfolio", "Edmond Hwang", "Edy Hwang"]} />

    <section className="wrapper-container">
      <h3>Edy Hwang</h3>
      <p className="descriptor">last updated August 26, 2024</p>

      <p>
        I am a UI/UX Designer and Front End Developer who has experience working in development and design oriented teams.
      </p>

      <div className="port-links text-right">
      <a
          title="GitHub"
          href="https://github.com/edhwang90"
          rel="noopener noreferrer"
          target="_blank"
        >
          GitHub
        </a>
        <a title="Resume"
           href="https://drive.google.com/file/d/1eGq_4s2CTjpsFUUJV2rpnQ45IXrQFBrZ/view?usp=sharing"
           rel="noopener noreferrer"
           target="_blank">Resume</a>
      </div>
    </section>

    <section className="wrapper-container">
      <h3>Projects</h3>

      <p>
        Most recently, through Springboard’s UX design course, I completed a <a href="/savr">mock GV design sprint</a> and a <a href="/fareshare">capstone project</a>.
      </p>

      <p>
        As a UI/UX designer, I previously worked on a <a href="/gov-financial-app">.gov website redesign</a>.
      </p>

      <p>
        In order to aid future development, I began an accessible <a href="/react-storybook">pattern library</a> built with React.
      </p>
    </section>

    <section className="wrapper-container">
      <h3>Background</h3>

      <p>
      While my professional experience is heavily UI-related, my passion and interest in UX have always driven my work - as such, I have pursued certifications such as NN/g and Springboard.
      </p>

      <p>
      Combined with my interest in providing sustainable, time-flexible, applications, I strive to provide balanced solutions to companies and users.
      </p>
    </section>

  </Layout>
)

export default IndexPage
